body {
  padding: 18px;
  box-sizing: border-box;
  border: 1px solid transparent;
  line-height: 2;
  max-width: 600px;
  margin: 0 auto;
}

h1 {
  margin: 0;
}

h2 {
  background: linear-gradient(transparent 70%, #a7d6ff 70%);
}

.title {
}

.introduction {
  .images {
    display: flex;
    gap: 4px;
    justify-content: center;

    img {
      width: 30%;
      max-width: 200px;
      border: 1px solid #ddd;
    }
  }
}

.editor {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  input[type="month"] {
    max-width: 320px;
    border: solid 1px #ccc;
    border-radius: 3px;
    padding: 10px;
    font-size: 1.3em;
    width: 100%;
  }

  input[type="button"] {
    width: 100%;
    max-width: 320px;
    padding: 10px;
    font-size: 1em;
    text-decoration: none;
    font-weight: bold;
    border: 2px solid #27acd9;
    background: #27acd9;
    color: #fff;
    transition: 0.5s;

    &:hover {
      color: #27acd9;
      background: #fff;
    }

    &:disabled {
      color: #bbb;
      background-color: white;
      border-color: #bbb;
    }
  }
}

.monthlycard_border {
  border: 1px solid #ddd;
}

.monthlycard__mitene_square {
  width: 320px;
  height: 320px;
  font-family: "Noto Sans Tangsa", sans-serif;

  background-color: white;
  color: black;

  display: flex;
  justify-content: center;
  align-items: center;

  .labels {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 215px;

    .month_text {
      font-weight: 700;
      font-size: 20px;
      line-height: 1em;
    }

    .month_number {
      font-weight: 700;
      font-size: 110px;
      line-height: 1em;
    }

    .year {
      font-weight: 700;
      font-size: 21px;
      line-height: 1em;
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-top: 1px solid #ddd;
  margin-top: 24px;
  padding-top: 12px;

  a.copyright {
    text-decoration: none;
  }
}
